<template>
  <span>
    {{FmtIncomeMatchStatus(value)}}
  </span>
</template>

<script>
  import {FmtEnum} from "@/util/enumUtil";

  export default {
    name: "IncomeAuthorizeMode",
    props: {
      value: {
        type: String,
        default: 'INITIAL'
      }
    },
    methods:{
      FmtIncomeMatchStatus() {
        const dms = this.$store.state.enums.IncomeAuthorizeMode;
        return FmtEnum(dms,this.value);
      }
    },
    created() {
      console.log("value的值是"+this.value);
    }
  }
</script>

<style scoped>

</style>